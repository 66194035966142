<template>
  <b-modal
    id="generate-invoice-modal"
    ok-only
    :ok-title="$t('Accept')"
    :cancel-title="$t('Cancel')"
    centered
    size="lg"
    modal-class="modal-primary"
    :visible="visible"
    :title="$t('payments.generate-invoice')"
    body-class="payment-modal"
    body-id="payment-modal"
    @close="$emit('close')"
    @hide="() => { $emit('close'); clearForm() }"
    @shown="setComponentReady"
  >
    <validation-observer ref="simpleRules">
      <invoice-template
        id="tour-invoice-template"
        :user-info="userInfo"
        :basic-user-info="basicUserInfo"
        :has-extra-payment="hasExtraPayment"
        :extra-payment-list="extraPaymentList"
        :date-range="dateRange"
        :add-more-extra-payment="addMoreExtraPayment"
        :extra-payment-category-options="extraPaymentCategoryOptions"
        :general-information="generalInformation"
        :banking-information="bankingInformation"
        @memo="val => { memo = val }"
        @paidInfo="val => {alreadyPaidInfo = val}"
        @source-updated="val => sourceWallet = val"
        @payroll-wallet-updated="val => payrollWalletUid = val"
      />

      <div class="mb-2">
        <b-form-checkbox
          v-model="hasExtraPayment"
          checked="true"
          name="check-button"
          switch
          inline
        >
          {{ $t('payments.extra-payment') }}
        </b-form-checkbox>
      </div>
    </validation-observer>
    <template #modal-footer>
      <b-row class="w-100">
        <b-col
          md="12"
          class="d-flex justify-content-end p-0"
        >
          <b-button
            variant="success"
            class="mr-1 p-1"
            @click="exportAsPdf"
          >
            {{ $t('Export As PDF') }}
          </b-button>

          <b-button
            variant="primary"
            :disabled="isProcessing"
            class="p-1"
            @click="handleOk"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span>
              {{ $t('payments.save-payment') }}
            </span>
          </b-button>
        </b-col>
      </b-row>
    </template>
    <!-- PDF ENGINE -->
    <VueHtml2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :html-to-pdf-options="{
        filename: 'hhh',
      }"
      :paginate-elements-by-height="1400"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <div
          ref="pdf-area"
          class="p-2 pdf-content"
        >
          <invoice-template
            :user-info="userInfo"
            :basic-user-info="basicUserInfo"
            :has-extra-payment="hasExtraPayment"
            :extra-payment-list="extraPaymentList"
            :date-range="dateRange"
            :add-more-extra-payment="addMoreExtraPayment"
            :general-information="generalInformation"
            :extra-payment-category-options="extraPaymentCategoryOptions"
            :banking-information="bankingInformation"
          /></div>
      </section>
    </VueHtml2pdf>
    <!-- PDF ENGINE -->
  </b-modal>
</template>
<script>
import {
  BModal, BFormCheckbox, BButton, BSpinner,
} from 'bootstrap-vue'
import useApollo from '@/plugins/graphql/useApollo'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import VueHtml2pdf from 'vue-html2pdf'
import { setComponentReady } from '@/views/tour/tour'
import InvoiceTemplate from './InvoiceTemplate.vue'

export default {
  components: {
    BModal,
    BFormCheckbox,
    BButton,
    BSpinner,
    VueHtml2pdf,
    InvoiceTemplate,
    ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userInfo: {
      type: Object,
      default: () => {},
    },
    dateRange: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      setComponentReady,
      hasExtraPayment: true,
      extraPaymentCategoryOptions: [],
      extraPaymentList: [],
      generalInformation: null,
      bankingInformation: null,
      isProcessing: false,
      basicUserInfo: {},
      required,
      memo: 'Programming Services',
      alreadyPaidInfo: {
        alreadyPaid: false,
        remarks: '',
      },
      payrollWalletUid: null,
      sourceWallet: null,
    }
  },
  computed: {
    fileName() {
      return `${this.userInfo.name}_${this.dateRange.start_date}_${this.dateRange.end_date}`
    },
  },
  watch: {
    userInfo: {
      handler(newValue) {
        if (newValue.uuid) {
          this.extraPaymentList = []
          this.getUserGeneralInfo()
        }
      },
      deep: true,
    },
  },
  created() {
    this.getExtraPaymentCategory()
  },
  methods: {
    getUserGeneralInfo() {
      this.isProcessing = true
      useApollo.users.getGeneralSetting(this.userInfo.uuid).then(response => {
        const { extraInformations } = response.data.user
        this.basicUserInfo = {
          id: response.data.user.id,
          email: response.data.user.email,
        }
        const extraInformationData = extraInformations.data.map(info => ({ ...info.information }))
        this.generalInformation = extraInformationData.filter(extra => extra.__typename === 'ContactInformation').slice(-1)[0] ?? null
        this.bankingInformation = extraInformationData.filter(extra => extra.information?.__typename === 'BillingInformation' || extra.information?.__typename === 'AchBillingInformation' || extra.information?.__typename === 'FxBillingInformation').slice(-1)[0] ?? null
      }).finally(() => { this.isProcessing = false })
    },
    getExtraPaymentCategory() {
      useApollo.payroll.getExtraPaymentCategory().then(response => {
        this.extraPaymentCategoryOptions = response.data.extraPaymentCategories.data.map(payment => ({
          label: payment.displayName,
          value: payment.name,
        }))
        this.extraPayment = {
          ...this.extraPayment,
          category: this.extraPaymentCategoryOptions[0]?.value,
        }
      })
    },
    savePayment() {
      const params = {
        userUid: this.userInfo.uuid,
        projectUid: this.$store.state.project.selectedProject,
        between: { startDate: this.FORMAT_DATE(this.dateRange.start_date, 'YYYY-MM-DD'), endDate: this.FORMAT_DATE(this.dateRange.end_date, 'YYYY-MM-DD') },
        extraPayments: this.hasExtraPayment ? [...this.extraPaymentList.map(payment => ({ ...payment, amount: Number(payment.amount) }))] : [],
        paymentMemo: this.memo,
        payrollWalletUid: this.payrollWalletUid,
        sourceWallet: this.sourceWallet,
      }
      if (this.alreadyPaidInfo.alreadyPaid) {
        params.alreadyPaidInfo = {
          alreadyPaid: this.alreadyPaidInfo.alreadyPaid,
          remarks: this.alreadyPaidInfo.remarks,
        }
      }
      this.isProcessing = true
      useApollo.payroll.postPayment(params).then(response => {
        this.showSuccess(response.data.makeUserPayment.message)
        this.$emit('onSave')
      }).catch(error => {
        this.showErrorMessage(error)
      }).finally(() => { this.isProcessing = false })
    },
    handleOk() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) this.savePayment()
      })
    },
    addMoreExtraPayment() {
      this.extraPaymentList.push({
        category: 'bonus',
        payrollWalletUid: null,
        remarks: '',
        amount: 0,
      })
    },
    removeExtraPayment(index) {
      this.extraPaymentList.splice(index, 1)
    },
    exportAsPdf() {
      this.$refs.html2Pdf.downloadPdf()
    },
    clearForm() {
      this.alreadyPaidInfo = {
        alreadyPaid: false,
      }
    },
  },
}
</script>
<style lang="scss">
.payment-modal {
    max-height: calc(100vh - 143px);
    overflow-y: auto;
  }
.pdf-content {
  .hide-on-pdf {
    display: none;
  }
}
</style>
