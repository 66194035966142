<template>
  <b-modal
    id="update-payment-setting-modal"
    ok-only
    :ok-title="$t('Accept')"
    :cancel-title="$t('Cancel')"
    modal-class="modal-primary"
    centered
    :title="`Manage Payment Settings for ${userInfo.name}`"
    size="lg"
    no-close-on-backdrop
    @hide="$emit('close')"
    @shown="setComponentReady"
  >
    <validation-observer ref="simpleRules">
      <div id="tour-manage-payment">
        <b-form>
          <b-row>
            <b-col
              cols="6"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Payment Type"
                rules="required"
              >
                <b-form-group :label="$t('Payment Type')">
                  <b-form-select
                    v-model="form.payment_type"
                    :options="paymentType"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="6"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Rate"
                rules="required|min:0"
              >
                <b-form-group :label="$t('Rate')">
                  <b-input-group class="input-group-merge">
                    <b-input-group-prepend is-text>
                      <i class="fa fa-money" />
                    </b-input-group-prepend>
                    <b-form-input
                      id="vi-amount"
                      v-model="form.amount"
                      type="number"
                      step="any"
                      min="0"
                      placeholder="Amount"
                    />
                  </b-input-group>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
            >
              <validation-provider
                #default="{ errors }"
                name="Effective Date"
                rules="required"
              >
                <b-form-group :label="$t('Effective Date')">
                  <b-form-datepicker
                    v-model="form.effective_from"
                    class="text-nowrap"
                    :date-format-options="COMMON.DATE_PICKER_FORMAT"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
        <div class="small mb-1 text-muted">
          {{ $t('Payment History') }}
        </div>
        <b-overlay :show="loadingHistory">
          <b-table
            :fields="columns"
            :items="history"
            show-empty
            responsive
          >
            <template #head()="data">
              <span class="text-capitalize">{{ data.label }}</span>
            </template>
            <template #cell(amount)="data">
              <span
                class="text-bold"
                :class="{
                  'blurry-text': data.item.isAmountBlur
                }"
                role="button"
                @click="data.item.isAmountBlur = !data.item.isAmountBlur"
              >
                {{ $store.getters['project/COMPANY_CURRENCY'] }}  {{ number_format(data.item.amount) }}
              </span>
            </template>
            <template #cell(effectiveFrom)="data">
              {{ timezone ? timeWithTimeZone(data.item.effectiveFrom, timezone, COMMON.DATE_FORMAT ) : '-' }}
            </template>
            <template #cell(effectiveTo)="data">
              {{ timezone ? timeWithTimeZone(data.item.effectiveTo, timezone,COMMON.DATE_FORMAT ) : '-' }}
            </template>
          </b-table>
        </b-overlay>
      </div>
      <pagination
        :total-items="totalHistory"
        :per-page="perPage"
        @onPaginationChange="getPaymentHistory"
      />
    </validation-observer>
    <template #modal-footer>
      <b-row>
        <b-col
          md="12"
          class="d-flex justify-content-end"
        >
          <b-button
            variant="primary"
            :disabled="isProcessing"
            @click="savePaymentSettings"
          >
            <b-spinner
              v-show="isProcessing"
              small
            />
            <span>{{ $t('payments.update-payment-settings') }}</span>
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal, BFormGroup, BTable, BForm, BRow, BCol, BInputGroupPrepend, BFormInput, BInputGroup, BFormSelect, BFormDatepicker, BButton, BSpinner, BOverlay,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import Pagination from '@/views/common/components/Pagination.vue'
import useApollo from '@/plugins/graphql/useApollo'
import { setComponentReady } from '@/views/tour/tour'

export default {
  components: {
    BModal,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BFormDatepicker,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BTable,
    Pagination,
    BOverlay,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      setComponentReady,
      paymentType: [
        { text: 'Hourly', value: 'HOURLY' },
        { text: 'Fixed', value: 'FIXED' },
      ],
      today: new Date(),
      form: {
        payment_type: 'HOURLY',
        effective_from: new Date(),
        amount: 0,
      },
      isProcessing: false,
      required,
      min,
      columns: [
        { label: 'Payment Type', key: 'paymentType' },
        { label: 'Rate', key: 'amount', tdClass: 'text-bold' },
        { label: 'Effective From', key: 'effectiveFrom' },
        { label: 'Effective To', key: 'effectiveTo' },
      ],
      history: [],
      totalHistory: 0,
      perPage: 3,
      loadingHistory: false,
      timezone: this.$store.state.project.company?.timezoneOffset?.name,
    }
  },
  watch: {
    userInfo: {
      handler(newValue) {
        if (newValue && newValue.uuid) {
          this.getPaymentHistory()
        }
        this.form = {
          ...this.form,
          effective_from: this.FORMAT_DATE(this.userInfo.effectiveFrom),
          amount: this.userInfo.pay_rate,
        }
      },
      deep: true,
    },
  },
  methods: {
    getPaymentHistory(page = 1) {
      this.loadingHistory = true
      const projectId = this.$store.state.project.selectedProject
      useApollo.payroll.getUserPaymentSettingHistory({
        userId: this.userInfo.uuid,
        page,
        first: this.perPage,
        projectUUid: projectId,
      }).then(response => {
        this.history = response.data.user.paymentSettings.data.map(data => ({
          ...data,
          paymentType: this.titleCase(data.paymentType),
          isAmountBlur: true,
        }))
        this.totalHistory = response.data.user.paymentSettings.total
      }).finally(() => { this.loadingHistory = false })
    },
    savePaymentSettings() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isProcessing = true
          useApollo.payroll.updatePaymentSetting({
            projectUid: this.$store.state.project.selectedProject,
            userUid: this.userInfo.uuid,
            amount: parseFloat(this.form.amount),
            paymentType: this.form.payment_type,
            effectiveFrom: (new Date(this.form.effective_from)).toISOString().slice(0, 10),
          }).then(response => {
            this.showSuccess(response.data.updateUserPaymentInfo.message)
            this.$bvModal.hide('update-payment-setting-modal')
            this.$emit('onSave')
          }).catch(error => {
            this.showErrorMessage(error)
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
  },
}
</script>
<style>
  .text-bold {
    font-weight: bold;
  }
</style>
