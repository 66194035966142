<template>
  <div>
    <b-table
      id="tour-invoice-history-table"
      class="table-min-height"
      :items="invoices"
      :fields="columns"
      hover
      striped
      bordered
      responsive
      show-empty
    >
      <template #head()="row">
        <span class="text-capitalize">{{ row.label }}</span>
      </template>
      <!-- Date Range -->
      <template #cell(from)="data">
        {{ timeWithTimeZone( data.item.from, $store.state.project.company.timezoneOffset.name, COMMON.DATE_FORMAT) }} -
        {{ timeWithTimeZone( data.item.to, $store.state.project.company.timezoneOffset.name, COMMON.DATE_FORMAT) }}
      </template>

      <!-- Net Amount -->
      <template #cell(calculatedAmount)="data">
        <span :class="data.item.rejectedAt ? 'strikethrough': ''">
          {{ formatAmount(data.item.calculatedAmount - sumExtraPayment(data.item.extraPayments.data).amount) }}
        </span>
      </template>

      <!-- Payable Amount -->
      <template #cell(paidAmount)="data">
        <span :class="data.item.rejectedAt ? 'strikethrough': ''">
          <b> {{ formatAmount(data.item.paidAmount) }}</b>
        </span>
      </template>

      <!-- Extra Amount -->
      <template #cell(extraAmount)="data">
        <div
          :class="data.item.rejectedAt ? 'strikethrough': ''"
          :set="extraAmount = sumExtraPayment(data.item.extraPayments.data)"
        >
          <span
            :id="`extra-${data.index}`"
            :class="data.item.rejectedAt ? 'strikethrough': ''"
          >
            {{ formatAmount(extraAmount.amount) }}
          </span>

          <b-tooltip
            v-if="extraAmount.category.length"
            :target="`extra-${data.index}`"
            placement="bottom"
          >
            <div class="text-left">
              <span
                v-for="(category, index) of extraAmount.category"
                :key="index"
              >
                {{ category }}
              </span>
            </div>
          </b-tooltip>
        </div>
      </template>

      <!-- Invoiced At -->
      <template #cell(invoicedAt)="data">
        {{ timeWithTimeZone( data.item.invoicedAt, $store.state.project.company.timezoneOffset.name, COMMON.DATE_FORMAT) }}
      </template>

      <!-- Verified At -->
      <template #cell(verifiedAt)="data">
        <span v-if="data.item.verifiedAt">
          {{ timeWithTimeZone( data.item.verifiedAt, $store.state.project.company.timezoneOffset.name, COMMON.DATE_FORMAT) }}
        </span>
        <span v-else-if="data.item.updator && data.item.updator?.uuid !== userInfo?.uuid">
          -
        </span>
        <span v-else-if="data.item.rejectedAt">
          <b-badge
            v-b-tooltip.hover="'Invoice Rejected'"
            size="sm"
            class="text-light"
            variant="danger"
          >
            {{ $t('payments.rejected') }}
          </b-badge>
        </span>
        <span v-else>
          <b-badge
            v-b-tooltip.hover="'Verification Pending'"
            size="sm"
            class="text-light"
            variant="warning"
          >
            {{ $t('payments.pending') }}
          </b-badge>
        </span>
      </template>

      <!-- Payment -->
      <template #cell(action)="data">
        <b-overlay :show="data.item.isPaymentProgress">
          <span v-if="data.item.rejectedAt">
            <b-badge
              v-b-tooltip.hover="`Payment Rejected by ${data.item.updator.name}`"
              size="sm"
              class="text-light"
              variant="danger"
            >
              Rejected
            </b-badge>
          </span>
          <b-dropdown
            v-else-if="(!data.item.isPaid && canEditPayment) && data.item.verifiedAt"
            v-b-modal.confirm-payment
            text="Pay"
            variant="outline-primary"
            split-variant="primary"
            split
            @click="$emit('pay', data.item)"
          >
            <template #button-content>
              <feather-icon icon="DollarSignIcon" />  Pay
            </template>
            <b-dropdown-item
              v-b-modal.cancel-payment-modal
              variant="danger"
              @click.stop="$emit('cancel-payment', data.item)"
            >
              Cancel Payment
            </b-dropdown-item>
          </b-dropdown>
          <span v-else>
            {{ timeWithTimeZone( data.item.paidOn, $store.state.project.company.timezoneOffset.name, COMMON.DATE_FORMAT) }}
          </span>
        </b-overlay>
      </template>

      <template
        v-if="invoices.length"
        slot="bottom-row"
      >
        <td
          align="right"
          class="font-weight-bold"
        >
          {{ $t('table.total') }}
        </td>
        <td
          align="right"
          class="font-weight-bold"
        >
          {{ `${formatAmount(invoices.filter(invoice => !invoice.rejectedAt).map(invoice => invoice.calculatedAmount - sumExtraPayment(invoice.extraPayments.data).amount).reduce((a, b) => a+b, 0).toFixed(2))}` }}
        </td>
        <td
          align="right"
          class="font-weight-bold px-1"
        >
          <!-- eslint-disable-next-line vue/no-parsing-error -->
          {{ invoices.filter(invoice => !invoice.rejectedAt).map(invoice => sumExtraPayment(invoice.extraPayments.data).amount).reduce((a, b) => a+b, 0) < 0 ? '-' : '' }}
          {{ `${formatAmount(Math.abs(invoices.filter(invoice => !invoice.rejectedAt).map(invoice => sumExtraPayment(invoice.extraPayments.data).amount).reduce((a, b) => a+b, 0).toFixed(2)))}` }}
        </td>
        <td
          align="right"
          class="font-weight-bold"
        >
          {{ `${formatAmount(invoices.filter(invoice => !invoice.rejectedAt).map(invoice => invoice.paidAmount).reduce((a, b) => a+b, 0).toFixed(2))}` }}
        </td>
        <td colspan="3" />
      </template>
    </b-table>

    <b-pagination
      v-if="total > perPage"
      v-model="currentPage"
      class="float-right"
      :total-rows="total"
      :per-page="perPage"
      aria-controls="tour-invoice-history-table"
    />
  </div>
</template>

<script>
import {
  BTable, BPagination, BBadge, BTooltip, BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    BTable,
    BPagination,
    BBadge,
    BTooltip,
    BOverlay,
    BDropdown,
    BDropdownItem,
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      default: () => 10,
    },
    total: {
      type: Number,
      default: () => 0,
    },
    exportType: {
      type: String,
      default: () => {},
    },
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentPage: 1,
      columns: [
        {
          key: 'from',
          label: 'Invoice Date Range',
          tdClass: 'text-nowrap',
        },
        {
          key: 'calculatedAmount',
          label: 'Net Amount',
          tdClass: 'text-nowrap text-right',
        },
        {
          key: 'extraAmount',
          label: 'Extra Amount',
          tdClass: 'text-nowrap text-right px-1',
        },
        {
          key: 'paidAmount',
          label: 'Payable Amount',
          tdClass: 'text-nowrap text-right',
        },
        {
          key: 'invoicedAt',
          label: 'Invoiced At',
          tdClass: 'text-nowrap',
        },
        {
          key: 'verifiedAt',
          label: 'Verified On',
          tdClass: 'text-nowrap',
        },
        {
          key: 'action',
          label: 'Payment',
          tdClass: 'text-nowrap',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('permissions', ['canEditPayment']),
  },
  watch: {
    currentPage(val) {
      this.$emit('paginate', val)
    },
  },
  methods: {
    sumExtraPayment(extraPayment) {
      const category = []
      let totalAmount = 0
      const { currency } = this.$store.state.project.company
      extraPayment.forEach(extra => {
        const categoryName = extra.payment.record?.category?.displayName
        totalAmount += Number(extra.payment?.amount)
        category.push(`${categoryName} - ${currency} ${extra.payment?.amount} ${extra.payment.record?.remarks ? `(${extra.payment.record?.remarks})` : ''}`)
      })
      return {
        amount: totalAmount,
        category,
      }
    },
  },
}
</script>

<style scoped>
.strikethrough {
  text-decoration: line-through;
  color: #ea5455;
}
</style>
